import styles from "./Disclaimer.module.css";
import { Navbar } from "../../../components/Navbar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Unauthorized } from "../../../components/Unauthorized";
import { StoredState } from "../../../lib/storedState";

export const Disclaimer = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  const handleAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "accept") {
      setIsAccepted(true);
    }
  };

  return (
    <section className={styles.disclaimerMainContainer}>
      {redirectLogin && <Unauthorized />}
      <div className={styles.disclaimerBackToHome}>
        <Link to="/home" className={styles.backLink}>
          <span>
            <i className="fa-duotone fa-solid fa-chevron-left"></i>
          </span>
        </Link>
      </div>
      <div className={styles.disclaimerMessageContainer}>
        <h2>Link with HealthLink</h2>
        <p>
          Before engaging with the AI-enabled health coach, please note that the
          information provided is not intended to replace professional medical
          advice. We recommend consulting with a qualified healthcare provider
          for personalized medical guidance. Please, be aware that HealthLink360
          is currently in the testing phase, and any insights or recommendations
          should be considered as educational and not medical advice.
        </p>
        <label className={styles.acceptRadio}>
          <input
            type="radio"
            value="accept"
            onChange={(event) => handleAccept(event)}
          />
          I accept
        </label>
        <a
          href="/coach/ai-coach"
          className={
            isAccepted
              ? styles.continueButtonActive
              : styles.continueButtonDeactivate
          }
        >
          Continue
        </a>
      </div>
      <Navbar />
    </section>
  );
};
