import { MouseEventHandler, useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { useBasicInformation } from "../../hooks/useBasicInformation";
import { deleteJwt, resetBasicInformation } from "../../lib/storeFunctions";
import styles from "./Home.module.css";
import { Link } from "react-router-dom";
import { StoredState } from "../../lib/storedState";
import { Unauthorized } from "../../components/Unauthorized";

export const Home = () => {
  const completeBasicInformation = useBasicInformation();
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  if (!completeBasicInformation) {
    return <div>Complete basic information expected</div>;
  }
  const [email, basicInformation] = completeBasicInformation;

  const handleResetBasicInformation: MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();
    resetBasicInformation();
  };

  const handleSignOut: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    deleteJwt();
  };

  return (
    <section className={styles.homeContainer}>
      {redirectLogin && <Unauthorized />}
      <h1>Welcome, {basicInformation.firstName}</h1>
      <div className={styles.buttonsMainContainer}>
        <div className={styles.buttons}>
          <Link to="/goals" className={styles.buttonContainer}>
            Goals
            <span>
              <i className="fa-solid fa-bullseye"></i>
            </span>
          </Link>
          <Link
            to="/activity/input-physical-activity"
            className={styles.buttonContainer}
          >
            Activity
            <span>
              <i className="fa-solid fa-person-running"></i>
            </span>
          </Link>
        </div>
        <div className={styles.buttons}>
          <Link
            to="/nutrition/nutrition-home"
            className={styles.buttonContainer}
          >
            Nutrition
            <span>
              <i className="fa-solid fa-carrot"></i>
            </span>
          </Link>
          <Link
            to="/symptoms/symptoms-message"
            className={styles.buttonContainer}
          >
            Symptoms
            <span>
              <i className="fa-solid fa-chart-simple"></i>
            </span>
          </Link>
        </div>
        <div className={styles.buttons}>
          <Link to="/#" className={styles.buttonContainer}>
            Medication
            <span>
              <i className="fa-solid fa-pills"></i>
            </span>
          </Link>
          <Link to="/#" className={styles.buttonContainer}>
            Status
            <span>
              <i className="fa-regular fa-face-smile"></i>
            </span>
          </Link>
        </div>
      </div>
      <div className={styles.resetInfoButtons}>
        <button onClick={handleResetBasicInformation}>
          Reset Basic Information
        </button>
        <button onClick={handleSignOut}>Sign Out</button>
      </div>
      <div className={styles.navbarContainer}>
        <Navbar />
      </div>
    </section>
  );
};
