import { useEffect, useState } from "react";
import { Unauthorized } from "../../../components/Unauthorized";
import styles from "./ScannerWarning.module.css";
import { Link } from "react-router-dom";
import { StoredState } from "../../../lib/storedState";

export const ScannerWarning = () => {
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  return (
    <section className={styles.scannerWarningContainer}>
      {redirectLogin && <Unauthorized />}
      <div className={styles.scannerWarningMessage}>
        <h1>Scan your face</h1>
        <p>
          Please, remain seated 5 minutes before the assessment and ensure your
          face is well lit from the front; try to avoid shadows and movement
          during the reading
        </p>
        <Link to="/scanner/video" className={styles.scannerWarningContinue}>
          Continue
        </Link>
      </div>
    </section>
  );
};
