import { Navbar } from "../../../components/Navbar";
import styles from "./ScannerResults.module.css";
import { useBasicInformation } from "../../../hooks/useBasicInformation";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Unauthorized } from "../../../components/Unauthorized";
import { StoredState } from "../../../lib/storedState";

export const ScannerResults = () => {
  const completeBasicInformation = useBasicInformation();
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  if (!completeBasicInformation) {
    return <div>Complete basic information expected</div>;
  }
  const [email, basicInformation] = completeBasicInformation;

  const currentDate = new Date().toDateString();

  return (
    <section className={styles.scannerResultsContainer}>
      {redirectLogin && <Unauthorized />}
      <div className={styles.title}>
        <h2>Hi, {basicInformation.firstName}</h2>
        <p>
          Last update <span>{currentDate}</span>
        </p>
      </div>
      <div className={styles.buttonsMainContainer}>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-heart"></i>
            </p>
            <p>Heart rate</p>
            <p>80 BPM</p>
          </div>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-heart-pulse"></i>
            </p>
            <p>HRV</p>
            <p>60 MS</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-lungs"></i>
            </p>
            <p>Respiration rate</p>
            <p>15 RPM</p>
          </div>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-brain"></i>
            </p>
            <p>Stress level</p>
            <p>Low</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-droplet"></i>
            </p>
            <p>Blood pressure</p>
            <p>136/97</p>
          </div>
        </div>
      </div>
      <Link className={styles.navigationButton} to="/scanner/charts">
        Charts
      </Link>
      <div className={styles.navbarContainer}>
        <Navbar />
      </div>
    </section>
  );
};
