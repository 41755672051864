import styles from "./SymptomsList.module.css";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Unauthorized } from "../../../components/Unauthorized";
import { StoredState } from "../../../lib/storedState";

const POSSIBLE_SYMPTOMS = [
  "Fever or Chills",
  "Cough",
  "Shortness fo breath or difficulty breathing",
  "Fatigue",
  "Muscle or body aches",
  "Headache",
  "New loss of taste or smell",
  "Sore throat",
  "Congestion or runny nose",
  "Nausea or vomiting",
  "Diarrhea",
  "None",
] as const;

export const SymptomsList = () => {
  const [symptoms, setSymptoms] = useState([] as string[]);
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValues = new Set(symptoms);
    const { id, checked } = event.target;
    if (checked) {
      if (id === "Select all") {
        POSSIBLE_SYMPTOMS.filter((i) => i !== "None").forEach((i) =>
          currentValues.add(i),
        );
      }

      if (id === "None") {
        currentValues.clear();
      } else {
        currentValues.delete("None");
      }

      currentValues.add(id);
    } else {
      currentValues.delete("Select all");
      currentValues.delete(id);
    }
    setSymptoms([...currentValues]);
  };

  return (
    <section className={styles.symptomsListContainer}>
      {redirectLogin && <Unauthorized />}
      <form>
        <div className={styles.titleSymptomsContainer}>
          <Link to="/home" className={styles.backButton}>
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <h2>Symptoms</h2>
        </div>
        <div className={styles.symptomsCheckboxes}>
          {POSSIBLE_SYMPTOMS.map((symptom) => (
            <div key={symptom} className={styles.symptomCheckbox}>
              <input
                type="checkbox"
                id={symptom}
                name="symptom"
                value={symptom}
                checked={Boolean(symptoms.find((i) => i === symptom))}
                onChange={handleFieldChange}
              />
              <label htmlFor={symptom}>{symptom}</label>
            </div>
          ))}
        </div>
      </form>
      <a href="/home" className={styles.symptomButton}>
        Submit
      </a>
    </section>
  );
};
