import { PartialBasicInformation } from "./basicInformation";
import { FoodType } from "./nutrition";
import { PhysicalActivityInformation } from "./physicalActivity";

export type StoredState = {
  jwt: string | null;
  basicInformation: {
    [email: string]: PartialBasicInformation;
  };
  physicalActivity: {
    [activityName: string]: PhysicalActivityInformation;
  };
  goals: {
    steps: number;
    calories: number;
  };
  nutrition: {
    [date: string]: {
      [time: string]: FoodType[];
    };
  };
};

type SubscribeFn = (storedState: StoredState) => void;

const subscribers = new Set<SubscribeFn>();

export const subscribe = (fn: SubscribeFn): (() => void) => {
  subscribers.add(fn);
  return () => subscribers.delete(fn);
};

export const readState = (): StoredState => {
  const defaultState: StoredState = {
    jwt: null,
    basicInformation: {},
    physicalActivity: {},
    goals: { steps: 0, calories: 0 },
    nutrition: {
      [new Date().toLocaleDateString("en-US").slice(0, 10)]: {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: [],
      },
    },
  };
  try {
    // TODO: add validations here and recover from faulty stored states
    const parsedState: StoredState | null = JSON.parse(
      localStorage.getItem("storedState") ?? "null",
    );
    return parsedState ?? defaultState;
  } catch (error) {
    console.error("Error parsing storedState", error);
    return defaultState;
  }
};

export const storeState = (storedState: StoredState): void => {
  localStorage.setItem("storedState", JSON.stringify(storedState));
  subscribers.forEach((subscriber) => subscriber(storedState));
};
