import { Link } from "react-router-dom";

import styles from "./Unauthorized.module.css";
import { deleteJwt } from "../../lib/storeFunctions";

export const Unauthorized = () => {
  return (
    <section className={styles.unauthorizedContainer}>
      <div className={styles.unauthorizedMessageContainer}>
        <p>Please, sign up again to refresh the access token</p>
        <Link
          to="/sign-in"
          className={styles.signUpRefresh}
          onClick={() => deleteJwt()}
        >
          Sign up
        </Link>
      </div>
    </section>
  );
};
