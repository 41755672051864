import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ScannerVideo.module.css";
import { Wave } from "../../../components/Wave";
import { Unauthorized } from "../../../components/Unauthorized";
import { StoredState } from "../../../lib/storedState";

export const ScannerVideo = () => {
  const [isPlaying, setIsPlaying] = useState<Boolean>(false);
  const videos = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  const navigateResults = () => navigate("/scanner/results");

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    let mediaStream: MediaStream;
    if (!videos.current) return;

    const stream = async () => {
      if (!videos.current) return;
      videos.current.style.objectFit = "cover";

      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user",
          height: { ideal: screenHeight },
        },
        audio: false,
      });

      const track = mediaStream.getVideoTracks()[0];
      videos.current.srcObject = mediaStream;

      videos.current.controls = true;
      setIsPlaying(true);
      if (!videos.current.srcObject) {
        videos.current.srcObject = mediaStream;
      }
      videos.current.controls = false;
    };

    const navigateToResults = () =>
      setTimeout(() => {
        mediaStream.getVideoTracks()[0].stop();
        navigateResults();
      }, 10e3);

    if (redirectLogin) {
      return;
    } else {
      stream().catch(console.error);
      navigateToResults();
    }
  }, [redirectLogin]);

  return (
    <section className={styles.scannerContainer}>
      {redirectLogin && <Unauthorized />}
      <div className={styles.faceScannerContainer}>
        {!redirectLogin && (
          <>
            <span className={styles.scannerOval}></span>
            <video
              ref={videos}
              className={styles.videoTag}
              autoPlay
              playsInline
            ></video>
            <div className={styles.instructions}>
              <div className={styles.lineContainer}>
                {isPlaying && !redirectLogin && <Wave />}
              </div>
              <p>Place your face inside the outlined oval</p>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
