export type MessageList = {
  id: number;
  conversation_uuid: string;
  message_order: number;
  message_role: string;
  message_text: string;
};

type conversationsResponse = {
  messages: MessageList[];
};

export const conversations = async ({
  jwt,
  uuid,
  message,
  endpoint,
}: {
  jwt: string | null | undefined;
  uuid: string;
  message: string;
  endpoint: URL;
}): Promise<conversationsResponse | null> => {
  if (!uuid) return null;

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${jwt}`);
  headers.append("Content-Type", "application/json");
  const apiEndpoint = `${endpoint}/${uuid}`;

  const response = await fetch(apiEndpoint, {
    method: "POST",
    body: JSON.stringify({ message: message }),
    headers,
  });
  const responseToJson = await response.json();

  return responseToJson;
};
