import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./AICoachChat.module.css";
import { StoredState } from "../../../lib/storedState";
import { usePromptGenerator } from "../../../lib/prompt";
import { conversations, MessageList } from "../../../lib/conversations";
import { Link } from "react-router-dom";
import { Unauthorized } from "../../../components/Unauthorized";

export const AICoachChat = () => {
  const promptWithUserData = usePromptGenerator();
  const [currentUuid, setCurrentUuid] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<MessageList[]>([]);
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const apiEndpoint = new URL(
    "/conversations",
    process.env.REACT_APP_API_BASE_URL,
  );

  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const createConversation = async () => {
    setLoading(true);
    const uuid = uuidv4();
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
    headers.append("Content-Type", "application/json");

    const response = await fetch(apiEndpoint, {
      method: "POST",
      body: JSON.stringify({ uuid: uuid }),
      headers,
    });
    const responseToJson = await response.json();

    if (responseToJson.statusCode === 401) {
      setLoading(false);
      setRedirectLogin(true);
    }

    setLoading(false);
    setCurrentUuid(responseToJson.uuid);
  };
  const conversationCreated = useCallback(
    async () => createConversation(),
    [currentUuid],
  );

  useEffect(() => {
    conversationCreated();
  }, []);

  const initializeConversation = async () => {
    setLoading(true);
    const responseConversation = await conversations({
      jwt: parsedState?.jwt,
      uuid: currentUuid,
      message: promptWithUserData,
      endpoint: apiEndpoint,
    });
    if (!responseConversation) {
      return null;
    }

    setLoading(false);
    setMessages((prevState) => [
      ...prevState,
      responseConversation.messages[responseConversation.messages.length - 1],
    ]);
  };
  const conversationInitialized = useCallback(
    async () => initializeConversation(),
    [currentUuid],
  );

  useEffect(() => {
    conversationInitialized();
  }, [currentUuid]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const verifyToken = async () => {
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  const handleSubmit: FormEventHandler = async (event) => {
    setLoading(true);
    let generatedId = 0;
    event.preventDefault();
    const messageCopy = message;
    setMessage("");
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: generatedId++,
        conversation_uuid: currentUuid,
        message_order: 1,
        message_role: "user",
        message_text: messageCopy,
      },
    ]);

    const responseConversation = await conversations({
      jwt: parsedState?.jwt,
      uuid: currentUuid,
      message: messageCopy,
      endpoint: apiEndpoint,
    });
    if (!responseConversation) {
      return null;
    }

    setLoading(false);

    setMessages((prevState) => [
      ...prevState,
      responseConversation.messages[responseConversation.messages.length - 1],
    ]);
  };

  return (
    <section className={styles.chatbotContainer}>
      {redirectLogin && <Unauthorized />}
      <div className={styles.chatbotBackToHome}>
        <Link to="/home" className={styles.backLink}>
          <span>
            <i className="fa-duotone fa-solid fa-chevron-left"></i>
          </span>
        </Link>
      </div>
      <div className={styles.responseContainer}>
        <div className={styles.messagesList}>
          {messages.length === 0 && (
            <p>Chat with your Personal Health Coach now</p>
          )}

          {messages?.map((message) => (
            <div
              className={
                message.message_role === "assistant"
                  ? styles.messageContainerLeft
                  : styles.messageContainerRight
              }
              key={message.id}
            >
              {message.message_role === "assistant" && (
                <div className={styles.messageContainer}>
                  {message.message_text
                    .split("\n")
                    .map(
                      (text, index) =>
                        text.length > 1 && <p key={index}>{text}</p>,
                    )}
                </div>
              )}
              {message.message_role === "user" && (
                <div className={styles.messageContainer}>
                  {message.message_text
                    .split("\n")
                    .map(
                      (text, index) =>
                        text.length > 1 && <p key={index}>{text}</p>,
                    )}
                </div>
              )}
            </div>
          ))}
          {loading && (
            <div className={styles.messageContainerLoader}>
              <div className={styles.loader}></div>
            </div>
          )}
          <div ref={messagesEndRef} className={styles.containerRef}></div>
        </div>
        <form
          name="ai-chat-box"
          className={styles.keyboardContainer}
          onSubmit={handleSubmit}
        >
          <textarea
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          ></textarea>
          <button type="submit" className={styles.buttonMessage}>
            <i className="fa-solid fa-paper-plane"></i>
          </button>
        </form>
      </div>
    </section>
  );
};
