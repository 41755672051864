import styles from "./SymptomsMessage.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Unauthorized } from "../../../components/Unauthorized";
import { StoredState } from "../../../lib/storedState";

export const SymptomsMessage = () => {
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  return (
    <section className={styles.symptomsContainer}>
      {redirectLogin && <Unauthorized />}
      <div className={styles.backToHome}>
        <Link to="/home" className={styles.backButton}>
          <i className="fa-solid fa-chevron-left"></i>
        </Link>
      </div>
      <div className={styles.symptomsBox}>
        <h2>Symptoms</h2>
        <p>
          HealthLink360 Symptoms Tracker enables you to track your health and
          take proactive measures to protect yourself and others.
        </p>
      </div>
      <div className={styles.symptomsButtonContainer}>
        <a href="/symptoms/symptoms-list" className={styles.symptomsButton}>
          Got it
        </a>
      </div>
    </section>
  );
};
