import styles from "./ScannerCharts.module.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Navbar } from "../../../components/Navbar";
import { useBasicInformation } from "../../../hooks/useBasicInformation";
import { Unauthorized } from "../../../components/Unauthorized";
import { useEffect, useState } from "react";
import { StoredState } from "../../../lib/storedState";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

const heartData = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Heart Rate",
      data: [80, 80, 80, 80, 80, 80, 80],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

const lungsData = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Respiration Rate",
      data: [15, 15, 15, 15, 15, 15, 15],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

const stressData = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Stress Rate",
      data: [20, 20, 20, 20, 20, 20, 20],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

const bloodPressureData = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Pressure Rate",
      data: [60, 120, 88, 76, 90, 100, 68],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const ScannerCharts = () => {
  const completeBasicInformation = useBasicInformation();
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    const verifyToken = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/auth", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers,
      });
      const responseToJson = await response.json();

      if (responseToJson.statusCode === 401) {
        setRedirectLogin(true);
      }
    };

    verifyToken();
  }, []);

  if (!completeBasicInformation) {
    return <div>Complete basic information expected</div>;
  }
  const [email, basicInformation] = completeBasicInformation;
  const currentDate = new Date().toDateString();

  return (
    <section className={styles.scannerResultsContainer}>
      {redirectLogin && <Unauthorized />}
      <div className={styles.title}>
        <h2>Hi, {basicInformation.firstName}</h2>
        <p>
          Last scan <span>{currentDate}</span>
        </p>
      </div>
      <div className={styles.graphsMainContainer}>
        <div className={styles.graphContainer}>
          <Line
            data={heartData}
            options={options}
            className={styles.lineChartScanner}
          />
          <div className={styles.iconInfoContainer}>
            <i className="fa-solid fa-heart-pulse"></i>
            <p>You have an average result, congrats!</p>
          </div>
        </div>
        <div className={styles.graphContainer}>
          <Line
            data={lungsData}
            options={options}
            className={styles.lineChartScanner}
          />
          <div className={styles.iconInfoContainer}>
            <i className="fa-solid fa-lungs"></i>
            <p>You have an average result, congrats!</p>
          </div>
        </div>
        <div className={styles.graphContainer}>
          <Line
            data={stressData}
            options={options}
            className={styles.lineChartScanner}
          />
          <div className={styles.iconInfoContainer}>
            <i className="fa-solid fa-brain"></i>
            <p>Looks you have been stressed lately</p>
          </div>
        </div>
        <div className={styles.graphContainer}>
          <Line
            data={bloodPressureData}
            options={options}
            className={styles.lineChartScanner}
          />
          <div className={styles.iconInfoContainer}>
            <i className="fa-solid fa-droplet"></i>
            <p>Watch up your blood pressure!</p>
          </div>
        </div>
      </div>
      <div className={styles.navbarContainer}>
        <Navbar />
      </div>
    </section>
  );
};
