import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import { z } from "zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { usePartialBasicInformation } from "../../../hooks/usePartialBasicInformation";
import { useNavigate } from "react-router-dom";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeBasicHealthAndWellness } from "../../../lib/storeFunctions";
import styles from "./HealthAndWellness.module.css";
import Logo from "../../../assets/logo-color.png";

const HEIGHTS = [
  `Enter your height`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `6'`,
];
const WEIGHTS = [
  `Enter your weight`,
  `100 lb`,
  `110 lb`,
  `120 lb`,
  `130 lb`,
  `140 lb`,
  `150 lb`,
  `160 lb`,
  `170 lb`,
  `180 lb`,
  `190 lb`,
  `200 lb`,
  `210 lb`,
  `220 lb`,
  `230 lb`,
  `240 lb`,
  `250 lb`,
  `260 lb`,
  `270 lb`,
  `280 lb`,
];

const ZHealthWellness = z.object({
  weight: z.string(),
  height: z.string(),
  activityLevel: z.enum([
    "Sedentary",
    "Lightly Active",
    "Moderate",
    "Very Active",
  ]),
});

export const HealthAndWellness = () => {
  const navigate = useNavigate();
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [activityLevel, setActivityLevel] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const partialInformation = usePartialBasicInformation();
  if (!partialInformation) {
    return <div>Complete basic information expected</div>;
  }

  const handleFieldChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { name, value } = event.target;
    if (name === "weight") return setWeight(value);
    if (name === "height") return setHeight(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleHabits: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const {
      name,
      dataset: { value },
    } = event.currentTarget;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }
    if (name === "activityLevel") return setActivityLevel(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zHealthWellness = ZHealthWellness.safeParse({
      weight,
      height,
      activityLevel,
    });
    if (!zHealthWellness.success) {
      setShowErrors(true);
      return;
    }

    storeBasicHealthAndWellness(zHealthWellness.data);
    setShowErrors(false);
    navigate(nextBasicInformationScreen("HealthAndWellness"));
  };

  const zHealthWellness = ZHealthWellness.safeParse({
    weight,
    height,
    activityLevel,
  });
  const weightErrors = getErrors(zHealthWellness, ["weight"]);
  const heightErrors = getErrors(zHealthWellness, ["height"]);
  const activityLevelErrors = getErrors(zHealthWellness, ["activityLevel"]);

  return (
    <section className={styles.healthAndWellnessContainer}>
      <figure className={styles.healthAndWellnessLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form
        onSubmit={handleSubmit}
        className={styles.healthAndWellnessForm}
        id="health-and-wellness-info"
      >
        <h2>Health and Wellness</h2>
        <div className={styles.healthAndWellnessInputContainer}>
          <select name="weight" onChange={handleFieldChange}>
            {WEIGHTS.map((weight) => (
              <option key={weight} value={weight}>
                {weight}
              </option>
            ))}
          </select>
          <Errors show={showErrors} errors={weightErrors} />
        </div>
        <div className={styles.healthAndWellnessInputContainer}>
          <select name="height" onChange={handleFieldChange}>
            {HEIGHTS.map((height) => (
              <option key={height} value={height}>
                {height}
              </option>
            ))}
          </select>
          <Errors show={showErrors} errors={heightErrors} />
        </div>
        <div className={styles.healthAndWellnessFormQuestion}>
          <p>How would you rate your physical activity level?</p>
          <div className={styles.healthAndWellnessFormButtons}>
            <button
              name="activityLevel"
              data-value="Sedentary"
              onClick={handleHabits}
              className={
                activityLevel === "Sedentary"
                  ? styles.selected
                  : styles.notSelected
              }
            >
              Sedentary
            </button>
            <button
              name="activityLevel"
              data-value="Lightly Active"
              onClick={handleHabits}
              className={
                activityLevel === "Lightly Active"
                  ? styles.selected
                  : styles.notSelected
              }
            >
              Lightly Active
            </button>
          </div>
          <div className={styles.healthAndWellnessFormButtons}>
            <button
              name="activityLevel"
              data-value="Moderate"
              onClick={handleHabits}
              className={
                activityLevel === "Moderate"
                  ? styles.selected
                  : styles.notSelected
              }
            >
              Moderate
            </button>
            <button
              name="activityLevel"
              data-value="Very Active"
              onClick={handleHabits}
              className={
                activityLevel === "Very Active"
                  ? styles.selected
                  : styles.notSelected
              }
            >
              Very Active
            </button>
          </div>
          <Errors show={showErrors} errors={activityLevelErrors} />
        </div>
      </form>
      <button
        type="submit"
        className={styles.healthAndWellnessNextButton}
        form="health-and-wellness-info"
      >
        Next
      </button>
    </section>
  );
};
